import styled, { keyframes } from "styled-components";

const fadein = keyframes`
  from {
    width: 0px;
    opacity: 0;
  }

  to {
    width:  100%;
    opacity: 0.4;
  }


`;

export const Container = styled.div`
  width: 100%;
  height: max-content;

  display: flex;
  justify-content: center;

  animation: ${fadein} 0.3s linear;
  animation-delay: 150ms;
  animation-fill-mode: backwards;

  .grid {
    display: grid;
    gap: 0% 3px;
  }


  .grid-template-columns {
    grid-template-columns: 15% 12% 12% 12% 12% 12% 12% 8% 2%;
    /* grid-template-columns: 14% 9% 10% 10% 10% 8% 8% 10% 8% 8% 2%; */
    align-items: center;
    justify-items: center;
    text-align: center;

    @media (max-width: 600px) {
      grid-template-columns: 30% 30% 30% 30% 30% 30% 30% 15% 15%;
    }

    div {
      margin-bottom: 0px!important;
    }
  }
`;

export const CreateForm = styled.div`
   width: 100%;
  margin-top: 10px;
  /* background-color: #f8f8f8; */

  border-radius: 10px;

  header {
    width: 100%;
    height: 34px;
    position: relative;
    background-color: #221c46;
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    align-items: center;


    h4 {
      width: max-content;
      height: max-content;
      margin-left: 10px;
      color: #f8f8f8;
      position: absolute;
      left: 0;
      top: 23%;
    }
  }

  main {
    margin-left: 30px;
    margin-right: 30px;

    @media (max-width: 600px) {
      overflow: auto
    }

    > div {
      display: flex;
      align-items: center;
    }
    button {
      margin-left: 10px;
      top: -2px
    }
  }
`;

export const CreateFooter = styled.footer`
  display: flex;
  justify-content: space-between;
  margin-left: 30px;
  margin-right: 30px;
  margin-bottom: 5px;
  div {
    width: 120px;
  }
`;


export const TitleTax = styled.strong``;
