import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  max-height: 400px;

  position: relative;

  form {
    /* margin-left: 30px;
    margin-right: 30px; */
  }

  header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;

    nav {
      display: flex;

      h2 {
        margin: 0;
        padding: 0;
        margin-left: 15px;
        text-align: center;
      }

      @media (max-width: 600px) {
          margin-left: 5px;
          font-size: 18px;
          text-align: left;
        }
    }

    div {
      display: flex;
      width: 90px;

      align-items: center;
      justify-content: center;

      button {
        height: 30px;
        width: 30px;
        margin: 5px;
        border: none;
        outline: none;
        color: #fff;
        transition: all 0.2s;
      }
    }
  }
`;

export const FilterContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;

  right: 9%;
  top: 10%;

  border: 1px solid #2d353c;

  width: 450px !important;
  height: auto;

  border-radius: 10px;
  background-color: #fff;
  z-index: 101;

  header {
    width: 100%;
    height: 45px;
    background-color: #2d353c;
    border-radius: 10px 10px 0 0;

    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #fff;
    padding: 0 10px;

    h4 {
      margin: auto 0;
      margin-left: 5px;
    }
    svg {
      width: 24px;
      height: 24px;
      transition: all 0.4s;
      color: #fff;

      :hover {
        color: #e05262;
      }
    }
  }

  main {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100% !important;

    padding: 0 15px;
    margin-top: 10px;
  }

  footer {
    width: 100%;
    margin-bottom: 10px;
    padding: 0 15px;
  }

  @media screen and (max-width: 530px) {
    top: 20%;
    left: 5%;
  }

  @media screen and (max-width: 765px) {
    top: 17%;
  }
`;

export const CreateForm = styled.div`
  border-radius: 10px;
  margin-bottom: 15px;
  padding-bottom: 15px;

  h1{
    font-size: 18px;
    text-align: center;
    margin-top: 10%;
    margin-bottom: 10%;
    color: #B30000;
  }

  header {
    width: 100%;
    height: 34px;
    position: relative;
    background-color: #221c46;
    margin-bottom: 5px;
    color: #fff;
    display: flex;
    p {
      font-weight: 700;
      margin: 10px 0 10px 0;
      margin-left: 15px
    }
  }
`;

export const ContainerOptions = styled.div`
  display: grid;
  gap: 2%;
  padding: 0% 2%;
  grid-template-columns: 49% 49%;

  main {
    width: 100%;
    background-color: #ff7375;
    color: #221c46;
    margin: 10px 0 10px 0;
    display: flex;
    align-items: center;
  }

  h3 {
      font-weight: 700;
      margin: 10px 0 10px 10px;
      font-size: 12px;
    }
`;

export const FormConsumer = styled.div`
  width: 100%;

  height: max-content;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0;
  /* margin-top: 20px;
  margin-bottom: 20px; */

  > span {
    width: 100%;

    display: flex;
    justify-content: center;
    margin-bottom: 10px;

    strong {
      width: 30%;
      text-align: center;
      color: #221c46;
    }
  }

  > ul {
    width: 100%;
    margin: 0;
    >div {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0;
      border-top: 1px solid #cccccc;

      li {
        height: 100%;
        width: 30%;
        padding: 0 5px;
        text-align: center;
        margin-top: 10px;
        margin-bottom: 10px;

        p {
          margin-bottom: 0;
          color: #221c46;
        }
      }
    }
  }
`;

export const TitleTax = styled.strong``;


