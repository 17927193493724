import styled from "styled-components";

export const Container = styled.div`
  header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    nav {
      display: flex;

       h2 {
        margin: 0;
        padding: 0;
        margin-left: 15px;
        text-align: center;
        color: #221c46;

        @media (max-width: 600px) {
          margin-left: 5px;
          font-size: 18px;
          text-align: left;
        }
      }
    }
  }

  .grid {
    display: grid;
    gap: 1%;
    padding: 0% 2%;
    margin-top: 10px;
  }

  .grid-template-columns-1 {
    grid-template-columns: 100%;
    text-align: center;

    .star {
      position: absolute;
      margin-top: 7px;
      margin-left: 30.5%;
      color: #bebebe;
    }

    .star:hover {
      color: #FF7375 !important;
      transition: 0.5s;
      cursor: pointer;
    }

    @media screen and (max-width: 1450px) {
      .star {
        margin-left: 35%;
      }
    }

    @media screen and (max-width: 1250px) {
      .star {
        margin-left: 30%;
      }
    }

    @media screen and (max-width: 1100px) {
      .star {
        margin-left: 25.3%;
      }
    }

    @media screen and (max-width: 1000px) {
      grid-template-columns: 100%;
      .star {
        margin-left: 27%;
      }
    }

    @media screen and (max-width: 900px) {
      .star {
        margin-left: 26%;
      }
    }

    @media screen and (max-width: 800px) {
      .star {
        margin-left: 25%;
      }
    }

    @media screen and (max-width: 750px) {
      .star {
        margin-left: 30%;
      }
    }

    @media screen and (max-width: 700px) {
      .star {
        margin-left: 33%;
      }
    }
  }

  .grid-template-columns-2 {
    grid-template-columns: 49% 50%;
    text-align: center;

    .star {
      position: absolute;
      margin-top: 7px;
      margin-left: 14.5%;
      color: #bebebe;
    }

    .star:hover {
      color: #FF7375 !important;
      transition: 0.5s;
      cursor: pointer;
    }

    @media screen and (max-width: 1250px) {
      .star {
        margin-left: 13%;
      }
    }

    @media screen and (max-width: 1100px) {
      .star {
        margin-left: 12.3%;
      }
    }

    @media screen and (max-width: 1000px) {
      grid-template-columns: 100%;
      .star {
        margin-left: 27%;
      }
    }

    @media screen and (max-width: 900px) {
      .star {
        margin-left: 26%;
      }
    }

    @media screen and (max-width: 800px) {
      .star {
        margin-left: 25%;
      }
    }

    @media screen and (max-width: 750px) {
      .star {
        margin-left: 30%;
      }
    }

    @media screen and (max-width: 700px) {
      .star {
        margin-left: 33%;
      }
    }
  }

  .grid-template-columns-4 {
    grid-template-columns: 19% 19% 19% 19% 19%;
  }

  .grid-template-columns-5 {
    grid-template-columns: 17% 17% 17% 17% 17% 4.5% 4.5%;

    button {
      margin-top: 20px;
      justify-self: center;
      border: none;
    }
  }

  @media screen and (max-width: 900px) {
    .grid-template-columns-4 {
      grid-template-columns: 49% 49%;
    }
    .grid-template-columns-5 {
      grid-template-columns: 16% 16% 16% 16% 16% 5% 5%;
    }
  }

  .graph {
    background: #f5f5f5;
  }
`;

export const FilterContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;

  right: 9%;
  top: 10%;

  border: 1px solid #221c46;

  width: 450px !important;
  height: auto;

/* border-radius: 10px; */
  background-color: #fff;
  z-index: 101;

  header {
    width: 100%;
    height: 45px;
    background-color: #221c46;
        /* border-radius: 10px 10px 0 0; */

    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #fff;
    padding: 0 10px;

    h4 {
      margin: auto 0;
      margin-left: 5px;
    }
    svg {
      width: 24px;
      height: 24px;
      transition: all 0.4s;
      color: #fff;

      :hover {
        color: #e05262;
      }
    }
  }

  main {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100% !important;

    padding: 0 15px;
    margin-top: 10px;
  }

  footer {
    width: 100%;
    margin-bottom: 10px;
    padding: 0 15px;
  }

  @media screen and (max-width: 530px) {
    top: 20%;
    left: 5%;
  }

  @media screen and (max-width: 765px) {
    top: 17%;
  }
`;

export const FormPagination = styled.div`
  display: flex;
  height: 30px;
  justify-content: center;
  align-items: center;
  margin-top: 5px;

  button {
    width: 30px;
    height: 100%;
    outline: none;
    border: none;
    font-weight: bold;
    font-family: 16px;
    margin-right: 6px;
    border-radius: 5px;
    background-color: #e05262;
    transition: all 0.4s;

    :hover {
      background-color: #221c46;
      color: #f8f8f8;
    }

    :disabled {
      background-color: #e05262;
      opacity: 0.6;
      color: #000;
      cursor: default;
    }
  }

  span {
    margin-left: 5px;
    margin-right: 10px;
  }

  select {
    border: none;
    border-radius: 5px;
  }
`;
